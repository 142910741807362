.our-team-sec {
  .title-sec {
    display: flex;
    align-items: center;
    width: max-content;
    background-color: #191919;
    padding: 10px 30px;
    border-radius: 15px;
    gap: 35px;
    margin-bottom: 25px;
    position: relative;
    @media (max-width: 991px) {
      gap: 20px;
      padding: 10px 15px;
    }
    img {
      @media (max-width: 575px) {
        height: 40px;
      }
    }
    h3 {
      color: #fff;
      font-size: 20px;
      @media (max-width: 991px) {
        font-size: calc(16px + (20 - 16) * ((100vw - 280px) / (991 - 280)));
      }
    }
  }
  .team-list {
    position: relative;
    padding-left: 20px;
    &::before {
      content: "";
      position: absolute;
      height: 100%;
      width: 10px;
      top: 0;
      left: 0;
      right: auto;
      bottom: 0;
      margin: auto;
      background: linear-gradient(#80F349, #68DF3B);
    }
    .team-member {
      h4 {
        color: #017100;
        font-size: 27px;
        font-weight: 700;
        text-transform: capitalize;
        @media (max-width: 991px) {
          font-size: calc(20px + (25 - 20) * ((100vw - 280px) / (991 - 280)));
        }
      }
      span {
        color: #747474;
        font-size: 16px;
        line-height: normal;
        display: block;
        margin-bottom: 10px;
      }
      a {
        margin: 15px 0;
        display: block;
        color: #2AB200;
        text-decoration: underline;
        font-size: 20px;
        font-weight: 700;
        font-style: italic;
        span{
          display: inline-block;
          vertical-align: bottom;
          background-color: #191919;
          border-radius: 5px;
          color: #0f1a20;
          font-size: 15px;
          height: 30px;
          width: 30px;
          margin-right: 8px;
          line-height: 25px;
          text-align: center;
          margin-bottom: 0;
          svg{
            vertical-align: middle;
            color: #ffffff;
          }
        }
        @media (max-width: 991px) {
          font-size: calc(16px + (20 - 16) * ((100vw - 280px) / (991 - 280)));
          margin: 10px 0;
        }
      }
    }
  }
}