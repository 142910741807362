.served-sec {
  .title-sec {
    display: flex;
    align-items: center;
    width: max-content;
    background-color: #191919;
    padding: 10px 30px;
    border-radius: 15px;
    gap: 35px;
    margin-bottom: 25px;
    position: relative;
    @media (max-width: 991px) {
      gap: 20px;
      padding: 10px 15px;
    }
    img {
      @media (max-width: 575px) {
        height: 40px;
      }
    }
    h3 {
      color: #fff;
      font-size: 20px;
      @media (max-width: 991px) {
        font-size: calc(16px + (20 - 16) * ((100vw - 280px) / (991 - 280)));
      }
    }
  }
  .served-sec-wrapper {
    position: relative;
    .right-img {
      z-index: -1;
      position: absolute;
      top: -70%;
      right: 0;
      left: auto;
      bottom: auto;
    }
  }
  ul {
    li {
      color: #017100;
      font-weight: 700;
      font-size: 18px;
      display: flex;
      margin-bottom: 10px;
      position: relative;
      @media (max-width: 991px) {
        margin-bottom: 8px;
      }
      @media (min-width: 575px) {
        margin-bottom: 5px;
      }
      &::before {
        content: '';
        position: absolute;
        height: 5px;
        width: 5px;
        top: 0;
        bottom: 0;
        margin: auto;
        border-radius: 50px;
        background-color: #017100;
      }
      span {
        padding-left: 20px;
        @media (max-width: 991px) {
          font-size: calc(15px + (16 - 15) * ((100vw - 280px) / (991 - 280)));
        }
      }
    }
  }
}