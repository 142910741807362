.contact-section-wrapper {
  margin: 50px 0;
  .sec-title {
    margin-bottom: 25px;
    font-size: 40px;
    font-weight: 700;
    letter-spacing: .03em;
    line-height: 1.2;
    text-transform: capitalize;
    @media only screen and (max-width: 991px) {
      font-size: calc(25px + (35 - 25) * ((100vw - 280px) / (991 - 280)));
    }
  }
  input[type=text],
  input[type=email] {
    background-color: #f9f9f9;
    &:focus {
      box-shadow: none;
    }
  }
  .form-control {
    height: calc(2.25rem + 27px);
    padding: 14px 30px;
    outline: 0;
    background-color: #f4f5f8;
    border: 1px solid #f4f5f8;
    color: #686a6f;
    font-size: 0.9rem;
    width: 100%;
  }
  textarea {
    &.form-control {
      background-color: #f9f9f9;
      height: 180px;
    }
  }
  .submit-btn {
    position: relative;
    font-size: 18px;
    line-height: 30px;
    padding: 15px 35px;
    font-weight: 500;
    color: #ffffff;
    background: linear-gradient(#1DB100, #68DF3B);
    border: none;
    outline: none;
    border-radius: 5px;
    transition: all .3s ease-in-out;
    &:hover {
      background: linear-gradient(#68DF3B, #1DB100);
    }
  }
}