.service-offerd-sec {
  text-align: right;
  .title {
    text-align: right;
    display: inline-flex;
    .title-sec {
      width: max-content;
      background-color: #191919;
      padding: 10px 30px;
      border-radius: 15px;
      gap: 35px;
      display: flex;
      align-items: center;
      position: relative;
      margin: 0 15px -30px 0;
      @media (max-width: 991px) {
        gap: 20px;
        padding: 10px 15px;
      }
      img {
        @media (max-width: 575px) {
          height: 40px;
        }
      }
      h3 {
        color: #fff;
        display: inline-block;
        font-size: 20px;
        @media (max-width: 991px) {
          font-size: calc(16px + (20 - 16) * ((100vw - 280px) / (991 - 280)));
        }
      }
    }
  }
  .service-sec {
    margin-bottom: 30px;
  }
  .service-offerd-wrap {
    position: relative;
    .left-img {
      z-index: -1;
      position: absolute;
      bottom: 0;
      right: auto;
      left: 0;
      top: auto;
    }
  }
  ul {
    background-color: #1DB100;
    color: #fff;
    padding: 60px 35px;
    border-radius: 15px;
    width: 75%;
    margin: 0 0 0 auto;
    @media (max-width: 991px) {
      width: 100%;
      padding: 50px 25px 25px;
    }
    li {
      font-weight: 700;
      font-size: 18px;
      margin-bottom: 10px;
      text-align: left;
      position: relative;
      display: flex;
      &::before {
        content: '';
        position: absolute;
        height: 5px;
        width: 5px;
        top: 0;
        bottom: 0;
        margin: auto;
        border-radius: 50px;
        background-color: #fff;
      }
      span {
        padding-left: 20px;
        @media (min-width: 1200px) {
          width: 62%;
        }
        @media only screen and (max-width: 991px) {
          padding-left: 15px;
          font-size: calc(16px + (18 - 16) * ((100vw - 280px) / (991 - 280)));
        }
      }
    }
  }
}