.service-content {
  color: #000;
  margin-bottom: 40px;
  p {
    margin-bottom: 24px;
    font-size: 20px;
    font-weight: 400;
    @media (min-width: 1200px) {
      width: 90%;
    }
    @media (max-width: 991px) {
      font-size: calc(16px + (18 - 16) * ((100vw - 280px) / (991 - 280)));
    }
  }
  strong,
  span {
    color: #017100;
    font-weight: 700;
  }
  strong {
    color: #2AB200;
  }
  ul {
    list-style: none;
    padding: 0;
    margin: 1.5rem 0;
    li {
      margin-bottom: 1rem;
      display: flex;
      align-items: center;
      img {
        @media (max-width: 575px) {
          height: 35px;
        }
      }
      span {
        color: #017100;
        font-weight: 700;
        font-size: 22px;
        margin-left: 25px;
        @media (max-width: 991px) {
          margin-left: 15px;
          font-size: calc(16px + (20 - 16) * ((100vw - 280px) / (991 - 280)));
        }
      }
    }
  }
}