.banner-wrapper {
  .banner-image {
    position: relative;
    .banner-main-image {
      box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
      @media screen and (min-width: 1600px) {
        height: 301px;
        object-fit: contain;
      }
    }
    .banner-logo {
      position: absolute;
      background-color: #191919;
      border-radius: 0 15px 15px 0;
      padding: 10px 35px;
      top: auto;
      bottom: -20px;
      left: 0;
      right: auto;
      height: max-content;
      @media (max-width: 992px) {
        padding: 8px 14px;
      }
      img {
        @media (max-width: 992px) {
          height: 80px;
          width: auto;
        }
        @media (max-width: 575px) {
          height: 36px;
          width: auto;
        }
      }
    }
  }
}