@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
body {
  font-family: var(--primary-font);
  font-size: 16px;
  line-height: 24px;
  overflow-x: hidden;
  position: relative;
  color: #000;
  background-color: var(--theme-color-primary);
}
a {
  text-decoration: none;
  color: #000;
}
a:hover {
  color: var(--mainColor);
}
ul,
li {
  list-style: none;
}
ul,
li,
h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
  padding: 0;
}
.clearfix {
  clear: both;
}
:root {
  // op - 000000
  --primary-font: "Roboto", sans-serif;
  --mainColor: #6EC62F;
  --mainColorOpacity: #f0f0f0;
  --mainColorbg: #f5f5f5;
}
.mb-100 {
  margin-bottom: 100px;
}
.mb-30 {
  margin-bottom: 30px;
}
.mb-40 {
  margin-bottom: 40px;
}
.mb-50 {
  margin-bottom: 50px
}
.mb-55 {
  margin-bottom: 55px
}
@media screen and (max-width:991px) {
  .mb-50 {
    margin-bottom: 50px;
  }
  .mb-40 {
    margin-bottom: 30px;
  }
}
@media screen and (max-width: 575px) {
  .mb-50 {
    margin-bottom: 30px;
  }
  .mb-40 {
    margin-bottom: 20px;
  }
}
::selection {
  color: var(--theme-color-primary);
  background-color: var(--mainColor);
}
::-webkit-scrollbar {
  width: 5px;
}
::-webkit-scrollbar-thumb {
  background: var(--mainColor);
}
@media (min-width: 1600px) {
  .container {
    // width: 1600px;
    width: 870px;
    max-width: 100%
  }
}
@media (max-width: 1600px) {
  .container {
    max-width: 100%;
    padding: 0 45px
  }
}
@media (max-width: 1023px) {
  .container {
    padding: 0 15px
  }
}
label {
  &.required::after {
    content: "*";
    color: red;
    margin-left: 3px;
  }
}
.btn-shadow {
  &::before,
  &::after {
    bottom: 15px;
    left: 10px;
    width: 50%;
    height: 20%;
    -webkit-box-shadow: 0 15px 10px rgba(0, 0, 0, 0.7);
    -moz-box-shadow: 0 15px 10px rgba(0, 0, 0, 0.7);
    box-shadow: 0 15px 10px rgba(0, 0, 0, 0.7);
    -webkit-transform: rotate(-5deg);
    -moz-transform: rotate(-5deg);
    -ms-transform: rotate(-5deg);
    -o-transform: rotate(-5deg);
    transform: rotate(-5deg);
    content: "";
    position: absolute;
    z-index: -2;
  }
  &::after {
    right: 10px;
    left: auto;
    -webkit-transform: rotate(5deg);
    -moz-transform: rotate(5deg);
    -ms-transform: rotate(5deg);
    -o-transform: rotate(5deg);
    transform: rotate(5deg);
  }
}