.footer-main {
  .footer-container {
    background-color: #ffffff;
    padding: 0 15px 15px 15px;
    text-align: center;
    .footer-content {
      .company-info {
        margin-bottom: 20px;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        @media (max-width: 650px) {
          display: block;
        }
        .footer-image {
          display: block;
          img {
            @media (max-width: 991px) {
              height: 70px;
              width: auto;
            }
          }
        }
      }
      .company-details {
        p {
          margin-bottom: 10px;
          @media (max-width: 991px) {
            font-size: calc(14px + (16 - 14) * ((100vw - 280px) / (991 - 280)));
          }
        }
        a {
          line-height: 1;
        }
        .company-web,
        .company-email,
        .contact-no{
          margin-bottom: 7px;
        }
        .contact-wrap{
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 25px;
          @media (max-width: 1024px) {
            display: block;
            word-break: break-all;
          }
        }
      }
      .footer-btn {
        margin: 0 0 0 50px;
        position: relative;
        z-index: 1;
        width: max-content;
        @media (max-width: 650px) {
          margin: 15px auto 0 auto;
        }
        .cta-button {
          background-color: #191919;
          color: #00b300;
          padding: 17px 26px;
          border-radius: 10px;
          font-weight: bold;
          display: inline-block;
          text-decoration: underline;
          position: relative;
          font-style: italic;
          font-size: 20px;
          transition: all .3s ease-in-out;
          &:hover {
            background-color: #555;
            text-decoration: none;
          }
        }
      }
    }
  }
}